import qzfStrTd from "@/components/table/qzf-str-td";
import qzfTd from "@/components/table/qzf-td";
import jmxzdm from "@/components/tax/jmxzdm";
import search from "@/components/Search/search"
import Pagination from "@/components/Pagination";
export default {
  install(Vue, options) {
    Vue.component("qzf-str-td", qzfStrTd); //str-td
    Vue.component("qzf-td", qzfTd); //td
    Vue.component("jmxzdm", jmxzdm); //减免性质代码
    Vue.component("search", search); 
    Vue.component('qzf-pagination',Pagination);
  },
};
