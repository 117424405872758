<template>
  <div class="big" style="display: inline-block">
    <el-button size="small" type="primary" @click="drawer = true">
      <el-icon><Filter /></el-icon> <span>高级筛选</span>
    </el-button>
    <div v-show="drawer" class="content">
      <slot></slot>
      <div style="flex: auto; float: right">
        <el-button size="small" @click="drawer = false">取消</el-button>
        <el-button size="small" type="primary" @click="confirmClick"
          >确认</el-button
        >
      </div>
    </div>
  </div>
  <div @click.stop="drawer = false" v-if="drawer" class="zz"></div>
</template>
<script>
export default {
  setup() {},
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    confirmClick() {
      this.drawer = false;
      this.$emit("success");
    },
  },
};
</script>

<style lang="scss" scoped>
.zz {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0);
  z-index: 999;
}
.big {
  position: relative;
}
.content {
  padding: 10px;
  min-width: 300px;
  min-height: 200px;
  position: absolute;
  top: 30px;
  left: 0;
  z-index: 1000;
  background: #fff;
  border-radius: 5px;
  border: 1px solid #e4e7ed;
}
</style>
