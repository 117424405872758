<template>
  <td :colspan="colspan" :rowspan="rowspan" :class="{ disabled: disabled }">
    <p @click="showInput" v-if="status">{{ nowAmount }}</p>
    <input
      ref="inputVal"
      v-else
      :disabled="disabled"
      v-model="code"
      @blur="inputChange($event)"
      type="text"
    />
  </td>
</template>
<script>
const MoneyTest = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;

// 金额添加千分位
const comdify = function (n) {
  if (!n) return n;
  let str = n.split(".");
  let re = /\d{1,3}(?=(\d{3})+$)/g;
  let n1 = str[0].replace(re, "$&,");
  return str.length > 1 && str[1] ? `${n1}.${str[1]}` : `${n1}.00`;
};
//去除千分位中的‘，’
const delcommafy = function (num) {
  if (!num) return num;
  num = num.toString();
  num = num.replace(/,/gi, "");
  return num;
};
const valdateFn = function (rule, val, cb) {
  setTimeout(() => {
    if (val) {
      let inputVal = delcommafy(val);
      if (rule.test(inputVal)) {
        cb();
      } else {
        cb("只能是数字金额,最多两位小数");
      }
    }
    cb();
  });
};
// 验证金额数字可以为负数
const moneyValid = function (rule, val, cb) {
  valdateFn(/((^-?[1-9]\d*)|^-?0)(\.\d{0,2}){0,1}$/, val, cb);
};
// 验证金额数字不可以为负数
const moneyNValid = function (rule, val, cb) {
  valdateFn(MoneyTest, val, cb);
};

// 获取输入框的值
const getInputValue = function (value) {
  let inputVal = Number(value) || "";
  return comdify(delcommafy(inputVal));
};

export default {
  props: {
    colspan: {
      type: Number,
      default: 1,
    },
    rowspan: {
      type: Number,
      default: 1,
    },
    amount: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    nowAmount: function () {
      this.code = this.amount;
      return getInputValue(this.amount);
    },
  },
  watch: {
    amount() {},
  },
  data() {
    return {
      status: true,
      code: "",
    };
  },
  created() {
    this.code = this.amount;
  },
  methods: {
    inputChange() {
      //处理他输入不是数字的字符串
      let amount = this.code * 1 ? this.code * 1 : 0;
      this.$emit("update:amount", String(amount));
      this.$emit("change");
      this.status = true;
    },
    showInput() {
      if (this.disabled) {
        return;
      }
      this.status = false;
      this.$nextTick(function () {
        this.$refs.inputVal.focus();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
td {
  // width: 100%;
  // display: inline-block;
  border-right: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  line-height: 25px;
  padding: 0 !important;
  color: #333;
  font-size: 14px;
}
input {
  width: calc(100% - 22px);
  line-height: 24px;
  // padding: 0 6px;
  background: rgb(241, 249, 253);
  font-size: 14px;
  padding-right: 20px;
  text-align: right;
  outline: none;
  color: #333;
  border: none;
}
p {
  // display: inline-block;
  //width: calc(100% - 10px);
  line-height: 27px;
  height: 27px;
  padding: 0 6px;
  font-size: 14px;
  text-align: right;
  color: #333;
}
.disabled {
  background: rgb(239, 239, 239);
}
</style>
