import { createApp } from "vue";
import App from "./App.vue";
import locale from "element-plus/lib/locale/lang/zh-cn";
import ElementPlus from "element-plus";
import * as ElIcons from "@element-plus/icons-vue";
import "element-plus/dist/index.css";
import store from "./store";
import router from "./router";
import "./style/element.scss";
import component from "@/utils/component"; //注册全局组件
import "./assets/icon/iconfont.css";
import eventBus from "@/utils/bus.js"; /// mitt 总线程引入
const app = createApp(App);
for (const iconName in ElIcons) {
  app.component(iconName, ElIcons[iconName]);
}
app.config.globalProperties.$bus = eventBus; //挂载全局属性
import {
  fplx,
  inventoryTypeFilter,
  inventoryCategoryFilter,
  VoucherSourceFilter,
  tableNameFilter,
  taxPayerTypeFilter,
  setUpStatusSb,
  setUpStatusGsSb,
  setUpStatusGsKk,
  setUpStatusGsIconSb,
  setUpStatusGsWordSb,
  setUpStatusKk,
  setUpStatusJc,
  setUpStatusIconJc,
  setUpStatusJcTotal,
  setUpStatusCj,
  setUpStatusQY,
  setUpStatusImport,
  setUpStatusMatch,
  batchSendSb,
  batchSendIconSb,
  batchSendWordSb,
  batchSendKk,
  batchSendWordKk,
  batchSendJc,
  batchSendCj,
  batchSendIconCj,
  setUpStatusGsZf,
  setUpStatusGsIconZf,
  setUpStatusGsWordZf,
  setUpStatusJzCj,
  setUpStatusJzCjIcon,
  cityFilter,
  yearAcessStatus,
  yearAcessIconStatus,
  yearAcessWordStatus,
  batchSendYearSb,
} from "@/utils/filter";
import { parseTime, getHeight, getLastDay } from "@/utils/index";
import {
  copy,
  message,
  queueMessage,
  copyComName,
  adjustKey,
} from "@/utils/fun";
app.mixin({
  methods: {
    $qzfMessage: message,
    $qzfCopy: copy,
    $fplx: fplx,
    $queueMessage: queueMessage,
    $inventoryTypeFilter: inventoryTypeFilter, //存货类别
    $inventoryCategoryFilter: inventoryCategoryFilter, //存货类型
    $VoucherSourceFilter: VoucherSourceFilter, //凭证来源
    $tableNameFilter: tableNameFilter, // 税务报表名称
    $taxPayerTypeFilter: taxPayerTypeFilter, //纳税人类型
    $setUpStatusSb: setUpStatusSb,
    $setUpStatusKk: setUpStatusKk,
    $setUpStatusJc: setUpStatusJc,
    $setUpStatusIconJc: setUpStatusIconJc,
    $setUpStatusJcTotal: setUpStatusJcTotal,
    $setUpStatusCj: setUpStatusCj,
    $setUpStatusQY: setUpStatusQY,
    $setUpStatusGsSb: setUpStatusGsSb,
    $setUpStatusGsKk: setUpStatusGsKk,
    $setUpStatusGsIconSb: setUpStatusGsIconSb,
    $setUpStatusGsWordSb: setUpStatusGsWordSb,
    $setUpStatusImport: setUpStatusImport,
    $setUpStatusMatch: setUpStatusMatch,
    $batchSendSb: batchSendSb,
    $batchSendIconSb: batchSendIconSb,
    $batchSendWordSb: batchSendWordSb,
    $batchSendKk: batchSendKk,
    $batchSendWordKk: batchSendWordKk,
    $batchSendJc: batchSendJc,
    $batchSendCj: batchSendCj,
    $batchSendIconCj: batchSendIconCj,
    $setUpStatusGsZf: setUpStatusGsZf,
    $setUpStatusGsIconZf: setUpStatusGsIconZf,
    $setUpStatusGsWordZf: setUpStatusGsWordZf,
    $setUpStatusJzCj: setUpStatusJzCj,
    $setUpStatusJzCjIcon: setUpStatusJzCjIcon,
    $yearAcessStatus: yearAcessStatus,
    $yearAcessIconStatus: yearAcessIconStatus,
    $yearAcessWordStatus: yearAcessWordStatus,
    $batchSendYearSb: batchSendYearSb,
    $cityFilter: cityFilter,
    $parseTime: parseTime, //格式化时间 parseTime(v.checkDate, "{y}-{m}-{d}")
    $getHeight: getHeight,
    $getLastDay: getLastDay, //获取账期最后一天
    $copyComName: copyComName,
    $adjustKey: adjustKey,
  },
});
const debounce = (fn, delay) => {
  let timer = null;
  return function () {
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  };
};

const _ResizeObserver = window.ResizeObserver;
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16);
    super(callback);
  }
};
app
  .use(ElementPlus, { locale })
  .use(component)
  .use(router)
  .use(store)
  .mount("#app");
