export function fplx(code) {
  if (code == "004") {
    return "增值税专用发票";
  } else if (code == "007") {
    return "增值税普通发票";
  } else if (code == "025") {
    return "卷式发票";
  } else if (code == "026") {
    return "电子普通发票";
  } else if (code == "005") {
    return "机动车发票";
  } else if (code == "006") {
    return "二手车销售统一发票";
  } else if (code == "028") {
    return "增值税电子专用发票";
  }
}

export function inventoryTypeFilter(type) {
  //存货类别filter
  if (type == 1) {
    return "原材料";
  } else if (type == 2) {
    return "库存商品";
  } else if (type == 3) {
    return "半成品";
  } else if (type == 4) {
    return "委托加工物资";
  } else if (type == 5) {
    return "周转材料";
  } else if (type == 6) {
    return "包装物";
  } else if (type == 7) {
    return "消耗性生物资产";
  } else if (type == 8) {
    return "工程物资";
  } else if (type == 9) {
    return "开发产品";
  } else if (type == 10) {
    return "发出产品";
  } else if (type == 11) {
    return "其他";
  }
}

export function inventoryCategoryFilter(type) {
  //存货类型filter
  if (type == 1) {
    return "初始化导入";
  } else if (type == 2) {
    return "进项入库-进项发票录入";
  } else if (type == 3) {
    return "暂估入库";
  } else if (type == 4) {
    return "生产商品入库(生产结转)";
  } else if (type == 6) {
    return "领料出库(生产结转)";
  } else if (type == 7) {
    return "商品结转出库(贸易结转)";
  } else if (type == 8) {
    return "抵消销项卖出(特殊)";
  } else if (type == 9) {
    return "销项卖出-销项发票录入";
  } else if (type == 10) {
    return "手工出库";
  } else if (type == 11) {
    return "手工入库";
  } else if (type == 12) {
    return "凭证关联出库";
  } else if (type == 13) {
    return "凭证关联入库";
  } else if (type == 31) {
    return "暂估回冲";
  } else if (type == 91) {
    return "销项回冲";
  }
}

export function VoucherSourceFilter(type) {
  //凭证来源filter
  if (type == "1") {
    return "进项";
  } else if (type == "2") {
    return "销项";
  } else if (type == "3") {
    return "费用";
  } else if (type == "4") {
    return "现金";
  } else if (type == "5") {
    return "票据";
  } else if (type == "6") {
    return "银行";
  } else if (type == "7") {
    return "工资";
  } else if (type == "8") {
    return "结转损益";
  } else if (type == "9") {
    return "计提税金";
  } else if (type == "10") {
    return "资产";
  } else if (type == "12") {
    return "存货结转";
  } else if (type == "13") {
    return "暂估回冲";
  } else if (type == "14") {
    return "暂估入库";
  } else if (type == "20") {
    return "进项认证";
  } else if (type == "30") {
    return "固定资产清理";
  } else if (type == "40") {
    return "汇兑损益";
  } else if (type == "100") {
    return "年末结转利润";
  } else if (type == "手工录入") {
    return "手工录入";
  }
}

export function tableNameFilter(type) {
  //税务报表名称过滤
  if (type == "kj_lrb") {
    return "利润表";
  } else if (type == "kj_zcfz") {
    return "资产负债表";
  } else if (type == "gs_vat") {
    return "增值税及附加税一般纳税人申报表";
  } else if (type == "gs_small_vat") {
    return "小规模增值税纳税及附加税申报表";
  } else if (type == "gs_deed") {
    return "财产和行为税纳税申报表";
  } else if (type == "gs_tax_quarter") {
    return "企业所得税申报表";
  } else if (type == "gs_gs") {
    return "综合所得个人所得税预扣预缴申报表";
  } else if (type == "gs_fjs") {
    return "附加税";
  } else if (type == "gs_whsyjsfsbbygz") {
    return "文化事业建设费申报表";
  } else if (type == "gs_stamp_duty_item") {
    return "印花税纳税申报表";
  } else if (type == "gs_xfs") {
    return "消费税";
  } else if (type == "gs_cbj") {
    return "残疾人就业保障金申报表";
  } else if (type == "gs_ghjf") {
    return "工会经费";
  } else if (type == "gs_fcs") {
    return "房产税土地税";
  } else if (type == "gs_fssr") {
    return "非税收入";
  } else if (type == "gs_stamp_duty_item_cb") {
    return "印花税纳税申报表";
  } else if (type == "gs_stamp_duty_item_jb") {
    return "印花税纳税申报表";
  } else if (type == "gs_sl") {
    return "水利建设专项收入";
  } else if (type == "gs_ljcl") {
    return "城市生活垃圾处置费";
  } else if (type == "gs_qtsr") {
    return "工会经费";
  } else if (type == "gs_gs_one") {
    return "个人生产经营所得(查账征收)";
  } else if (type == "kj_xjllb") {
    return "现金流量表";
  } else if (type == "gs_sb") {
    return "单位社会保险费日常申报(全责)";
  } else if (type == "gs_cwbb") {
    return "财务报表";
  } else if (type == "gs_tax_dqde") {
    return "个人所得税(定期定额)";
  } else if (type == "kj_cwbb") {
    return "财务报表";
  }
}

export function taxPayerTypeFilter(type) {
  if (type == "1") {
    return "小规模纳税人";
  } else if (type == "2") {
    return "一般纳税人";
  }
}

// 扣款状态
export function setUpStatusKk(type) {
  if (type == "0" || type == "") {
    return "未扣款";
  } else if (type == "1") {
    return "排队中";
  } else if (type == "2") {
    return "扣款中";
  } else if (type == "3") {
    return "扣款成功";
  } else if (type == "4") {
    return "无需扣款";
  } else if (type == "5") {
    return "扣款异常";
  }
}

// 申报状态
export function setUpStatusSb(type) {
  if (type == "0" || type == "") {
    return "未申报";
  } else if (type == "1") {
    return "排队中";
  } else if (type == "2") {
    return "申报中";
  } else if (type == "3") {
    return "申报完成";
  } else if (type == "4") {
    return "申报异常";
  } else if (type == "5") {
    return "税局处理中";
  } else if (type == "6") {
    return "已申报过";
  }
}

// 采集状态
export function setUpStatusCj(type) {
  if (type == "0") {
    return "未采集";
  } else if (type == "1") {
    return "排队中";
  } else if (type == "2") {
    return "采集中";
  } else if (type == "3") {
    return "采集完成";
  } else if (type == "4") {
    return "采集失败";
  } else if (type == "5") {
    return "异常";
  }
}

// 导入状态
export function setUpStatusImport(type) {
  if (type == "0") {
    return "未导入";
  } else if (type == "1") {
    return "排队中";
  } else if (type == "2") {
    return "导入中";
  } else if (type == "3") {
    return "导入完成";
  } else if (type == "4") {
    return "导入失败";
  } else if (type == "5") {
    return "错误";
  }
}

// 匹配状态
export function setUpStatusMatch(type) {
  if (type == "0") {
    return "未匹配";
  } else if (type == "1") {
    return "排队中";
  } else if (type == "2") {
    return "匹配中";
  } else if (type == "3") {
    return "匹配完成";
  } else if (type == "4") {
    return "匹配失败";
  } else if (type == "5") {
    return "错误";
  }
}
// 采集状态
export function setUpStatusQY(type) {
  if (type == "0") {
    return "未迁移";
  } else if (type == "1") {
    return "排队中";
  } else if (type == "2") {
    return "迁移中";
  } else if (type == "3") {
    return "迁移完成";
  } else if (type == "4") {
    return "迁移失败";
  } else if (type == "5") {
    return "异常";
  }
}
// 个税申报状态
export function setUpStatusGsSb(status, type) {
  if (status == 0 || status == "") {
    return "未申报";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "申报中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "3") {
      return "申报超时失败";
    }
    if (type == "2") {
      return "申报处理中";
    } else if (type == "4") {
      return "客户端未启动失败";
    } else if (type == "5") {
      return "申报异常";
    } else if (type == "6") {
      return "申报失败";
    } else if (type == "7") {
      return "申报成功，待缴款";
    } else if (type == "8") {
      return "自动检查中";
    } else if (type == "10") {
      return "申报成功，无需缴款";
    } else if (type == "11") {
      return "扣款中";
    } else if (type == "12") {
      return "扣款异常";
    } else if (type == "13") {
      return "申报成功，扣款成功";
    } else if (type == "14") {
      return "已申报过";
    } else if (type == "20") {
      return "申报成功";
    } else {
      return "未知状态";
    }
  }
}

export function setUpStatusGsKk(status, type) {
  if (status == 0 || status == "") {
    return "未开始";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "扣款中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "3") {
      return "申报超时失败";
    }
    if (type == "2") {
      return "申报处理中";
    } else if (type == "4") {
      return "客户端未启动失败";
    } else if (type == "5") {
      return "申报异常";
    } else if (type == "6") {
      return "申报失败";
    } else if (type == "7") {
      return "申报成功，待缴款";
    } else if (type == "8") {
      return "自动检查中";
    } else if (type == "10") {
      return "申报成功，无需缴款";
    } else if (type == "11") {
      return "扣款中";
    } else if (type == "12") {
      return "扣款异常";
    } else if (type == "13") {
      return "申报成功，扣款成功";
    } else if (type == "14") {
      return "已申报过";
    } else if (type == "20") {
      return "申报成功";
    } else {
      return "未知状态";
    }
  }
}

export function setUpStatusGsIconSb(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else {
    if (type == "3") {
      return "iconfont icon-cuowu";
    } else if (type == "2") {
      return "iconfont icon-info";
    } else if (type == "4") {
      return "iconfont icon-cuowu";
    } else if (type == "5") {
      return "iconfont icon-cuowu";
    } else if (type == "6") {
      return "iconfont icon-cuowu";
    } else if (type == "7") {
      return "iconfont icon-info";
    } else if (type == "8") {
      return "iconfont icon-info";
    } else if (type == "10") {
      return "iconfont icon-duihao";
    } else if (type == "11") {
      return "iconfont icon-info";
    } else if (type == "12") {
      return "iconfont icon-cuowu";
    } else if (type == "13") {
      return "iconfont icon-duihao";
    } else if (type == "14") {
      return "iconfont icon-duihao";
    }
  }
}

export function setUpStatusGsWordSb(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else {
    if (type == "3") {
      return "sbyc2";
    } else if (type == "2") {
      return "sbz";
    } else if (type == "4") {
      return "sbyc2";
    } else if (type == "5") {
      return "sbyc2";
    } else if (type == "6") {
      return "sbyc2";
    } else if (type == "7") {
      return "sbz";
    } else if (type == "8") {
      return "sbz";
    } else if (type == "10") {
      return "sbcg2";
    } else if (type == "11") {
      return "sbz";
    } else if (type == "12") {
      return "sbyc2";
    } else if (type == "13") {
      return "sbcg2";
    } else if (type == "14") {
      return "sbcg2";
    }
  }
}

// 税种申报 申报任务结果状态
export function batchSendSb(status, type) {
  if (status == 0 || status == "") {
    return "未申报";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "申报中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "2") {
      return "申报成功无需扣款";
    } else if (type == "3") {
      return "申报失败";
    } else if (type == "4") {
      return "提交金三处理中";
    } else if (type == "5") {
      return "申报成功,待缴款";
    } else if (type == "6") {
      return "申报成功,已缴款";
    } else if (type == "20") {
      return "已申报过，无需扣款";
    } else if (type == "21") {
      return "已申报过，待缴款";
    } else if (type == "22") {
      return "已申报过，已缴款";
    } else if (type == "40") {
      return "无需申报";
    } else if (type == "66") {
      return "已申报(非本通道申报)";
    } else {
      return "未知状态";
    }
  }
}

export function batchSendIconSb(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    } else if (type == "4") {
      return "iconfont icon-info";
    } else if (type == "5") {
      return "iconfont icon-info";
    } else if (type == "6") {
      return "iconfont icon-duihao";
    } else if (type == "20") {
      return "iconfont icon-duihao";
    } else if (type == "21") {
      return "iconfont icon-duihao";
    } else if (type == "22") {
      return "iconfont icon-duihao";
    } else if (type == "40") {
      return "iconfont icon-gantanhao gantanhao-blue";
    } else if (type == "66") {
      return "iconfont icon-duihao";
    } else {
      return "未知状态";
    }
  }
}

export function batchSendWordSb(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else {
    if (type == "2") {
      return "sbcg2";
    } else if (type == "3") {
      return "sbyc2";
    } else if (type == "4") {
      return "sbz";
    } else if (type == "5") {
      return "sbz";
    } else if (type == "6") {
      return "sbcg2";
    } else if (type == "20") {
      return "sbcg2";
    } else if (type == "21") {
      return "sbz";
    } else if (type == "22") {
      return "sbcg2";
    } else if (type == "40") {
      return "sbz";
    } else if (type == "66") {
      return "sbcg2";
    } else {
      return "未知状态";
    }
  }
}

// 税种缴款 扣款任务结果状态
export function batchSendKk(status, type) {
  if (status == 0 || status == "") {
    return "未扣款";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "扣款中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "2") {
      return "成功";
    } else if (type == "3") {
      return "失败";
    } else {
      return "未知状态";
    }
  }
}

// 税种缴款 扣款任务结果状态 字体颜色
export function batchSendWordKk(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else {
    if (type == "2") {
      return "sbcg2";
    } else if (type == "3") {
      return "sbyc2";
    } else {
      return "未知状态";
    }
  }
}

// 申报检查任务结果状态
export function batchSendJc(status, type) {
  if (status == 0 || status == "") {
    return "未检查";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "检查中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "2") {
      return "成功";
    } else if (type == "3") {
      return "失败";
    } else {
      return "未知状态";
    }
  }
}

// 采集发票
export function batchSendCj(status, type) {
  if (status == 0 || status == "") {
    return "未采集";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "采集中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "2") {
      return "成功";
    } else if (type == "3") {
      return "失败";
    } else if (type == "4") {
      return "税局审核中";
    } else if (type == "5") {
      return "成功（未勾选发票）";
    } else if (type == "6") {
      return "成功(非本通道申报)";
    } else {
      return "未知状态";
    }
  }
}

export function batchSendIconCj(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    } else if (type == "4") {
      return "iconfont icon-info";
    } else if (type == "5") {
      return "iconfont icon-duihao";
    } else if (type == "6") {
      return "iconfont icon-duihao";
    }
  }
}

// 检查状态
export function setUpStatusJc(status, type) {
  if (type == "task") {
    if (status == 0 || status == "") {
      return "未检查";
    } else if (status == 1) {
      return "排队中";
    } else if (status == 2) {
      return "检查中";
    } else if (status == 3) {
      return "检查完成";
    } else if (status == 4) {
      return "检查失败";
    }
  } else {
    if (status == "2") {
      return "检查通过";
    } else if (status == "3") {
      return "检查失败";
    } else if (status == "30") {
      return "有遗漏";
    } else if (status == "50") {
      return "已申报，有异常";
    }
  }
}
//检查状态++
export function setUpStatusJcTotal(status, type) {
  if (status == 0 || status == "") {
    return "未检查";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "检查中";
  }
  //  else if (status == 3) {
  //   return "检查完成";
  // }
  else if (status == 4) {
    return "检查失败";
  } else {
    if (type == 2) {
      return "检查通过";
    } else if (type == 3) {
      return "检查失败";
    } else if (type == 30) {
      return "有遗漏";
    }
  }
}
export function setUpStatusIconJc(status, type) {
  if (type == "task") {
    if (status == 0 || status == "") {
      return "iconfont icon-gantanhao";
    } else if (status == 1) {
      return "iconfont icon-info";
    } else if (status == 2) {
      return "iconfont icon-info";
    } else if (status == 3) {
      return "iconfont icon-duihao";
    } else if (status == 4) {
      return "iconfont icon-cuowu";
    }
  } else {
    if (status == "2") {
      return "iconfont icon-duihao";
    } else if (status == "3") {
      return "iconfont icon-cuowu";
    } else if (status == "30") {
      return "iconfont icon-gantanhao gantanhao-cw";
    } else if (status == "50") {
      return "iconfont icon-gantanhao gantanhao-yc";
    }
  }
}

// 个税作废状态
export function setUpStatusGsZf(status, type) {
  if (status == 0 || status == "") {
    return "未作废";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "申报中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "2") {
      return "成功";
    } else if (type == "3") {
      return "失败";
    }
  }
}

export function setUpStatusGsIconZf(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    }
  }
}

export function setUpStatusGsWordZf(status, type) {
  if (status == 0 || status == "") {
    return "wsb";
  } else if (status == 1) {
    return "sbz";
  } else if (status == 2) {
    return "sbz";
  } else if (status == 4) {
    return "sbyc2";
  } else {
    if (type == "2") {
      return "sbcg2";
    } else if (type == "3") {
      return "sbyc2";
    } else if (type == "30") {
      return "sbyc2";
    } else if (type == "50") {
      return "yczt";
    }
  }
}

// 采集科目余额表状态
export function setUpStatusJzCj(status, type) {
  if (status == 0 || status == "") {
    return "未采集";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "采集中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "2") {
      return "采集成功";
    } else if (type == "3") {
      return "采集失败";
    }
  }
}

export function setUpStatusJzCjIcon(status, type) {
  if (status == 0 || status == "") {
    return "iconfont icon-gantanhao";
  } else if (status == 1) {
    return "iconfont icon-info";
  } else if (status == 2) {
    return "iconfont icon-info";
  } else if (status == 4) {
    return "iconfont icon-cuowu";
  } else {
    if (type == "2") {
      return "iconfont icon-duihao";
    } else if (type == "3") {
      return "iconfont icon-cuowu";
    }
  }
}

// 城市筛选
export function cityFilter(type) {
  if (type == "beijing") {
    return "北京";
  } else if (type == "shandong") {
    return "山东";
  } else if (type == "qingdao") {
    return "青岛";
  } else if (type == "neimenggu") {
    return "内蒙古";
  } else if (type == "shanxi") {
    return "山西";
  } else if (type == "tianjin") {
    return "天津";
  } else if (type == "sichuan") {
    return "四川";
  } else if (type == "jiangsu") {
    return "江苏";
  } else if (type == "hebei") {
    return "河北";
  } else if (type == "xizang") {
    return "西藏";
  } else if (type == "qinghai") {
    return "青海";
  } else if (type == "henan") {
    return "河南";
  } else if (type == "shaanxi") {
    return "陕西";
  } else if (type == "hainan") {
    return "海南";
  } else if (type == "liaoning") {
    return "辽宁";
  } else if (type == "shanghai") {
    return "上海";
  } else if (type == "hunan") {
    return "湖南";
  } else if (type == "guangdong") {
    return "广东";
  } else if (type == "shenzhen") {
    return "深圳";
  } else if (type == "guangxi") {
    return "广西";
  } else if (type == "chongqing") {
    return "重庆";
  } else if (type == "yunnan") {
    return "云南";
  } else if (type == "xinjiang") {
    return "新疆";
  } else if (type == "anhui") {
    return "安徽";
  } else if (type == "hubei") {
    return "湖北";
  } else if (type == "guizhou") {
    return "贵州";
  } else if (type == "dalian") {
    return "大连";
  } else if (type == "ningbo") {
    return "宁波";
  } else if (type == "xiamen") {
    return "厦门";
  } else if (type == "ningxia") {
    return "宁夏";
  } else if (type == "jiangxi") {
    return "江西";
  } else if (type == "jilin") {
    return "吉林";
  } else if (type == "heilongjiang") {
    return "黑龙江";
  } else if (type == "zhejiang") {
    return "浙江";
  } else if (type == "fujian") {
    return "福建";
  } else if (type == "guizhou") {
    return "贵州";
  } else if (type == "gansu") {
    return "甘肃";
  }else if (type == "370000") {
    return "山东";
  } else if (type == "370200") {
    return "青岛";
  } else if (type == "150000") {
    return "内蒙古";
  } else if (type == "140000") {
    return "山西";
  } else if (type == "120000") {
    return "天津";
  } else if (type == "510000") {
    return "四川";
  } else if (type == "320000") {
    return "江苏";
  } else if (type == "130000") {
    return "河北";
  } else if (type == "540000") {
    return "西藏";
  } else if (type == "630000") {
    return "青海";
  } else if (type == "410000") {
    return "河南";
  } else if (type == "610000") {
    return "陕西";
  } else if (type == "460000") {
    return "海南";
  } else if (type == "210000") {
    return "辽宁";
  } else if (type == "310000") {
    return "上海";
  } else if (type == "430000") {
    return "湖南";
  } else if (type == "440000") {
    return "广东";
  } else if (type == "440300") {
    return "深圳";
  } else if (type == "450000") {
    return "广西";
  } else if (type == "500000") {
    return "重庆";
  } else if (type == "530000") {
    return "云南";
  } else if (type == "650000") {
    return "新疆";
  } else if (type == "340000") {
    return "安徽";
  } else if (type == "420000") {
    return "湖北";
  } else if (type == "520000") {
    return "贵州";
  } else if (type == "210200") {
    return "大连";
  } else if (type == "330200") {
    return "宁波";
  } else if (type == "350200") {
    return "厦门";
  } else if (type == "640000") {
    return "宁夏";
  } else if (type == "360000") {
    return "江西";
  } else if (type == "220000") {
    return "吉林";
  } else if (type == "230000") {
    return "黑龙江";
  } else if (type == "330000") {
    return "浙江";
  } else if (type == "350000") {
    return "福建";
  } else if (type == "620000") {
    return "甘肃";
  } else if (type == "110000") {
    return "北京";
  }
}

// 汇算清缴取数状态
export function yearAcessStatus(type) {
  if (type == 0) {
    return "未取数";
  } else if (type == 1) {
    return "取数中";
  } else if (type == 3) {
    return "取数成功";
  } else if (type == 4) {
    return "取数失败";
  }
}

export function yearAcessIconStatus(type) {
  if (type == 0) {
    return "iconfont icon-gantanhao";
  } else if (type == 1) {
    return "iconfont icon-info";
  } else if (type == 3) {
    return "iconfont icon-duihao";
  } else if (type == 4) {
    return "iconfont icon-cuowu";
  }
}

export function yearAcessWordStatus(type) {
  if (type == 0) {
    return "wsb";
  } else if (type == 1) {
    return "sbz";
  } else if (type == 3) {
    return "sbcg2";
  } else if (type == 4) {
    return "sbyc2";
  }
}

// 税种申报 申报任务结果状态
export function batchSendYearSb(status, type) {
  if (status == 0 || status == "") {
    return "未申报";
  } else if (status == 1) {
    return "排队中";
  } else if (status == 2) {
    return "申报中";
  } else if (status == 4) {
    return "失败";
  } else {
    if (type == "2") {
      return "申报成功";
    } else if (type == "3") {
      return "申报失败";
    } else {
      return "未知状态";
    }
  }
}
