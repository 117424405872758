<template>
  <router-view></router-view>
</template>

<script setup>
import { getUrlParams } from "./utils/fun";
import { onBeforeMount } from "vue";

onBeforeMount(() => {
  let key = getUrlParams(window.location.href, "key");
  if (key == "yundaizhang-dev" || key == "yundaizhang-prod") {
    document
      .querySelector(":root")
      .style.setProperty("--themeColor", "#88BF6A");
    document
      .querySelector(":root")
      .style.setProperty("--themeColorTableTh", "#80c7fd");
    document
      .querySelector(":root")
      .style.setProperty("--themeColorBtnText", "#80c7fd");

    document
      .querySelector(":root")
      .style.setProperty("--themeColorPlainFont", "#80C7FD");
    document
      .querySelector(":root")
      .style.setProperty("--themeColorPlainBg", "#e9f5ff");
    document
      .querySelector(":root")
      .style.setProperty("--themeColorPlainBco", "#80c7fd");
  }
});
</script>

<style lang="scss" rel="stylesheet/scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
:root {
  --themeColor: #17a2b8; //主题色
  --themeColorLeftMenu: #ecf7f9; //主题浅色
  --themeColorPlain: #f0f9f8; //主题浅色按钮
  --themeColorPlainBc: #c3e5e3; //主要色按钮plain的边框
  --themeColorTableTh: #17a2b8; //表格表头背景色
  --themeColorBtnText: #17a2b8; //文字按钮颜色
  //plain样式
  --themeColorPlainFont: #17a2b8;
  --themeColorPlainBg: #f0f9f8;
  --themeColorPlainBco: #c3e5e3;
}
</style>
