import request from "@/utils/request";

//减免性质代码
export function gsJmxzdm(data) {
  return request({
    url: "api/v1/gsJmxzdm",
    method: "post",
    data,
  });
}
// 保存税种信息
export function saveTaxCategory(data) {
  return request({
    url: "api/v1/saveTaxCategory",
    method: "post",
    data,
  });
}
// 税种信息列表
export function taxCategoryList(data) {
  return request({
    url: "api/v1/taxCategoryList",
    method: "post",
    data,
  });
}
// 税种信息删除
export function delOneTaxCategory(data) {
  return request({
    url: "api/v1/delOneTaxCategory",
    method: "post",
    data,
  });
}
//财务报表取数
export function kjCreate(data) {
  return request({
    url: "api/v1/kjCreate",
    method: "post",
    data,
  });
}
//财务报表查看
export function gsInfo(data) {
  return request({
    url: "api/v1/gsInfo",
    method: "post",
    data,
  });
}
//财务报表查看
export function gsEdit(data) {
  return request({
    url: "api/v1/gsEdit",
    method: "post",
    data,
  });
}
//发起任务
export function sendTask(data) {
  return request({
    url: "api/v1/sendTask",
    method: "post",
    data,
  });
}
//申报详情列表
export function taxTaskList(data) {
  return request({
    url: "api/v1/taxTaskList",
    method: "post",
    data,
  });
}

//撤销任务
export function quitTask(data) {
  return request({
    url: "api/v1/quitTask",
    method: "post",
    data,
  });
}


//生成报表
export function taxCreate(data) {
  return request({
    url: "api/v1/taxCreate",
    method: "post",
    data,
  });
}