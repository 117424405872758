import axios from "axios";
import { ElMessage } from "element-plus";
import store from "@/store";
const service = axios.create({
  baseURL: process.env.BASE_API, // api 的 base_url
  timeout: 600000, // request timeout
});

service.interceptors.request.use(
  (config) => {
    config.headers["FromKey"] = store.getters["commons/params"].fromKey;
    config.headers["FromToken"] = store.getters["commons/params"].fromToken;
    return config;
  },
  (response) => {
    console.log("返回23：" + response);
  },
  (error) => {
    console.log(error); // for debug
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    if(response.data.errNo > 0){
      ElMessage({
        message: response.data.msg,
        type: 'error',
        duration: 5 * 1000
      })
    }
    return response;
  },
  (error) => {
    console.log("err" + error); // for debug
    ElMessage({
      message: error.message,
      type: "error",
      duration: 5 * 1000,
    });
    return Promise.reject(error);
  }
);

export default service;
