
export const commons = {
  namespaced: true,
  state: {
    params: {}, //参数
  },
  mutations: {
    SET_PARAM: (state, data) => {
      state.params = data;
    },
  },
  actions: {
    setParam({ commit }, data) {
      data = Object.assign(commons.state.params, data);
      commit("SET_PARAM", data);
    },
  },
  getters: {
    params(state) {
      return state.params;
    },
  },
};