import { createStore } from "vuex";
import { commons } from '@/store/common'
export default createStore({
  modules: {
    commons,
  },
  state: {},
  getters: {},
});

